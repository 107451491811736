<template>
    <div class="content">
        <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />
        <Slider :slides="slides" v-slot="{ currentSlide }">
        <!-- <a :href="currentSlide.link" target="_blank"> -->
            <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
        <!-- </a> -->
        </Slider>
        <div class="content-product">
            <div class="pro-left content-item">
                <div class="bold-title pro-title size-20">PROUDUCT
                    &nbsp;
                    <span class="sub-title">产品中心</span>
                </div>
                <p class="pro-sub-title size-12">
                    INTROUDUCTION<br/>
                    产品简介
                </p>
                <div class="bold-title pro-class-title size-24">钣金加工</div>
                <p>
                    4.0柔性加工生产线 对于钣金、板料冲剪折弯自动化加工系统可根据用户工件的折弯工艺要求，定制折弯单元方案，最大限度满足客户需求。
                </p>
                <div class="pro-btn">
                    <button class="btn-orange-line" @click="jump('Product')">MORE+</button>
                </div>
            </div>

            <div class="pro-right">
                <ul class="nav-ul size-14">
                    <li v-for="(item,index) in navList" :key="index" @click="changeNavIndex(index)">
                        <span :class="{ active: index === activeNavIndex }">{{ item.typeName }}</span>
                    </li>
                </ul>
                <div>
                <Slider v-for="(val,index) in navList" :slides="val.productList" :key="index" v-show="index === activeNavIndex" v-slot="{ currentSlide }">
                    <div class="product-slider-list" v-for="(item,key) in currentSlide" :key="key">
                        <router-link :to="{ name:'ProductDetail' , params: { productId: item.id }}" >
                            <div class="product-slider"><img  :alt="item.productName" :title="item.productName" :src="item.productImgUrl" class="product-slider-img"></div>
                        </router-link>
                        <div class="product-slider-title size-14">{{ item.productName }}</div>
                    </div>
                </Slider>
                 <div style="clear:both;"></div>
                </div>
            </div>
            <div style="clear:both;"></div>
        </div>

        <div class="content-strength">
            <div class="strength-left content-item">
                <div class="bold-title strength-title size-24">易宝通实力优势</div>
                <div class="strength-sub-title size-24">4.0柔性加工生产线</div>
                <div class="strength-desc size-12">27年专注钣金、板料加工、喷涂、电泳加工一站式服务，为客户铸就好品质，提升产品价值！</div>
                <img src="@/assets/strength.png" alt="实力优势" />
            </div>
             <div class="strength-right">
                <div class="strength-item">
                    <img src="@/assets/equipment.png" alt="设备" />
                    <div class="strength-item-info">
                        <div class="strength-item-title size-12">高精度、全自动机器人设备</div>
                        <div class="strength-item-desc">拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等以保证高效率和高精度的加工。</div>
                    </div>
                </div>
                <div class="strength-item">
                    <img src="@/assets/set.png" alt="设置" />
                    <div class="strength-item-info">
                        <div class="strength-item-title">27年专注，丰富的行业经验</div>
                         <div class="strength-item-desc">27年专注钣金加工具备丰富的经验，能够对不同的钣金加工需求提供专业的解决方案和技术支持。</div>
                    </div>
                </div>
                <div class="strength-item">
                    <img src="@/assets/employee.png" alt="员工" />
                    <div class="strength-item-info">
                        <div class="strength-item-title">专业团队，高素质的员工</div>
                         <div class="strength-item-desc">专业团队具备高素质的员工队伍，包括技术人员、工程师、操作员等，拥有扎实的专业知识和技能，能够为客户提供高质量的加工服务。</div>
                    </div>
                </div>
                <div class="strength-item">
                    <img src="@/assets/home.png" alt="工厂" />
                    <div class="strength-item-info">
                       <div class="strength-item-title">66000m厂房、稳定的生产能力</div>
                        <div class="strength-item-desc">66000m厂房，4.0柔性加工生产线备灵活的生产能力，能够根据客户需求进行快速调整，包括加工工艺、生产流程、设备调配等，能够按时按质完成客户订单保证生产效率和质量稳定以满足客户的特殊需求。</div>
                    </div>
                </div>
                <div class="strength-item">
                    <img src="@/assets/service.png" alt="售后" />
                    <div class="strength-item-info">
                       <div class="strength-item-title">质量保证、优质的售后服务</div>
                        <div class="strength-item-desc">易宝通对加工过程进行严格的监控和质量控制，保证产品符合客户要求和国家相关标准，能够为客户提供及时、周到的技术支持和服务，解决客户在使用过程中遇到的问题和困难。</div>
                    </div>
                </div>
            </div>
             <div style="clear:both;"></div>
        </div>

        <div class="case-content">
            <div class="case-title size-20">CASE&nbsp;<span class="case-sub-title">应用案例</span></div>
            <div class="case-btn">
                <button class="btn-orange-line" @click="jump('CaseDetail')">MORE+</button>
            </div>
            <div class="case-item">
                <router-link class="case" v-for="(item,index) in caseList" :key="index" :to="{ name:'CaseDetail' , params: { caseId: item.id }}" >
                    <div class="case-img case-right"><img :src="item.caseImgUrl" :alt="item.caseName" /></div>
                    <div class="case-info">
                        <div class="date-info">
                            <div class="date size-14">{{ item.createTime.substring(5, 10) }}</div>
                            <div class="year">{{ item.createTime.substring(0, 4) }}</div>
                        </div>
                        <div class="case-name size-14">{{ item.caseName }}</div>
                        <div style="clear:both;"></div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="about-content">
            <div class="case-title title-about size-20">ABOUT&nbsp;<span class="case-sub-title">关于我们</span></div>
            <div class="about-item">
                <div class="about-left">
                    <div class="bold-title about-title size-24">走进易宝通</div>
                    <div class="about-sub-title size-24">专业技术水平，国际认证标准</div>
                    <div class="about-desc">湖北易宝通智能科技有限公司是深圳市速易宝智能科技有限公司的全资控股子公司，位于长江经济带国家级转型升级示范开发区—浠水经济开发区散花工业园，工业园占地40亩，有标准化的钣金、喷涂、装配等生产制造车间五栋，各类生产设备齐全，拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等。</div>
                    <div class="about-pro">
                        <div class="about-pro-item">
                            <div class="pro-item-top"><span class="pro-item-num bold-title size-24">2017</span><span  class="pro-item-more">年</span></div>
                            <div class="pro-item-bottom">公司成立</div>
                        </div>
                        <div class="about-pro-item">
                            <div class="pro-item-top"><span class="pro-item-num bold-title size-24">120000</span><span  class="pro-item-more">年</span></div>
                            <div class="pro-item-bottom">年生产总量</div>
                        </div>
                        <div class="about-pro-item">
                            <div class="pro-item-top"><span class="pro-item-num bold-title size-24">77</span><span class="pro-item-more">项</span></div>
                            <div class="pro-item-bottom">技术专利</div>
                        </div>
                        <div class="about-pro-item">
                            <div class="pro-item-top"><span class="pro-item-num bold-title size-24">10000</span><span  class="pro-item-more">个</span></div>
                            <div class="pro-item-bottom">成功案例</div>
                        </div>
                    </div>
                </div>
                <div class="about-right">
                    <img src="@/assets/about.png" alt="关于我们" />
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>

        <div class="news-content">
            <div class="case-title size-20">NEWS&nbsp;<span class="case-sub-title">新闻资讯</span></div>
             <div class="case-btn">
                <button @click="jump('News')" class="btn-orange-line">MORE+</button>
            </div>
            <Slider :slides="newsList" v-slot="{ currentSlide }">
                <div v-for="(item,key) in currentSlide" :key="key" :class="key == 0 ? 'news-slider news-left' : key == 1 ? 'news-slider news-center' : 'news-slider'">
                    <router-link :to="{ name:'NewsDetail' , params: { newsId: item.id }}">
                        <div class="news-item">
                            <div class="news-img-item"><img :src="item.newsImgUrl" alt="封面" class="news-img" /></div>
                            <div class="news-title size-12">{{ item.newsTitle }}</div>
                            <div class="news-time">{{ item.createTime.substring(0, 10) }}</div>
                            <div class="news-desc">{{ removeHtmlStyle(item.newsContent) }}</div>
                        </div>
                    </router-link>
                </div>
            </Slider>
        </div>

        <TheContact />
    </div>
</template>

<script>
import { getList } from "@/api/product/index";
import { getCaseList } from "@/api/case/index";
import { getSearchList } from "@/api/news/index";
import { removeHtmlStyle } from "@/utils";

export default {
  name: "Sidebar",
  created() {
    this.getProductList();
    this.getCaseList();
    this.getNewsList();
  },
  data() {
    return {
      proParams: {
        page: 1,
        limit: 12
      },
      newsParams: {
        page: 1,
        limit: 12
      },
      show: false,
      showText:"加载中...",
      // 实战课程
      slides: [
        {
          title: "banner",
          img: require("@/assets/banner.png")
        }
        // {
        //   title: 'banner',
        //   img:  require('@/assets/banner1.png'),
        // },
        // {
        //   title: 'banner',
        //   img: require('@/assets/banner2.png'),
        // }
      ],
      navList: [],
      activeNavIndex: 0,
      productList: [],
      caseList: [],
      newsList: []
    };
  },
  methods: {
    removeHtmlStyle,
    changeNavIndex(index) {
      this.activeNavIndex = index;
      // this.productList = this.navList[this.activeNavIndex].productList
      // console.log(this.productList)
    },
    jump(name) {
      this.$router.push({ name: name });
    },
    getProductList() {
      this.show = true;
      this.showText = "加载中...";
      this.navList = []
      getList(this.proParams)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            if(res.data.length > 0) {
                let info,product
                res.data.splice(0, 4).forEach((item) => {
                    info = {
                        id:item.id,
                        typeName:item.typeName,
                        productList:[]
                    }
                    product = []
                    item.productList.forEach((val,index) => {
                        product.push(val)
                        if((index+1)%3 == 0 || index == (item.productList.length -1)) {
                           if(product.length > 0) {
                             info.productList.push(product)
                           }
                           product = []
                        }
                    })
                    this.navList.push(info)
                });

                this.productList = this.navList[this.activeNavIndex].productList
            }
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    },
    getCaseList() {
      this.show = true;
      this.showText = "加载中...";
      getCaseList()
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            this.caseList = res.data.splice(0, 4)
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    },
    getNewsList() {
      this.show = true;
      this.showText = "加载中...";
      getSearchList(this.newsParams)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            this.newsList = []
            let product = []
            let that = this
            res.data.forEach((val,index) => {
                product.push(val)
                if((index+1)%3 == 0 || index == (res.data.length -1)) {
                    if(product.length > 0) {
                        that.newsList.push(product)
                    }
                    product = []
                }
            })
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    }
  }
};
</script>

<style scoped>
.sub-title {
  color: #2573da;
}

.content-product {
  margin: 50px 0px;
}

.pro-left {
  display: inline-block;
  width: 20%;
  margin: 0px 4% 0px 10%;
}

.pro-title {
  margin-bottom: 50px;
  /* font-size: 20px; */
}

.pro-sub-title {
  color: #8c8c8c;
  /* font-size: 12px; */
}

.pro-class-title {
  margin-bottom: 20px;
  /* font-size: 24px; */
}

.btn-orange-line {
  border: 1px solid #fabd8b;
  width: 120px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  color: #fabd8b;
}

.pro-btn {
  text-align: center;
}

.pro-right {
  position: relative;
  display: inline-block;
  width: 66%;
  background-color: #fff;
  float:right;
}

.pro-right .nav-ul {
  display: inline-block;
  /* font-size: 14px; */
  padding-left: 0px;
}

.pro-right .nav-ul li {
  display: inline-block;
  padding: 10px;
  font-weight: bold;
}

.pro-right .nav-ul li span {
  color: #525252;
  cursor: pointer;
}

.pro-right .nav-ul li span:hover {
  color: #1565cf;
  text-decoration: none;
}

.pro-right .nav-ul li .active {
  color: #1565cf;
  font-weight: bold;
  /* border-bottom:2px solid #1565cf; */
  text-decoration: none;
}

.product-slider-list {
   float: left;
    width: 31.3%;
    margin: 0px 0.5%;
}

.pro-right .product-slider {
  /* display:block; */
  /* float: left; */
  /* margin: 0px 5px; */
  /* width:32%; */
  /* max-height:24px; */
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 66.7%;
  margin-top: 20px;
  /* margin-right:12rpx; */
}

.pro-right .product-slider .product-slider-img {
  display: inline-block;
  /* width:100%; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.pro-right .product-slider-title {
  margin-top: 10px;
  /* font-size: 14px; */
  text-align: center;
}

.content-product /deep/ .carousel-indicators {
  bottom: -30px;
  left: 47%;
}

.content-strength {
  background-color: #043e8b;
  color: #fff;
  padding: 50px 6% 0px 10%;
}

.content-strength .strength-left {
  display: block;
  width: 46%;
  float: left;
}

.content-strength .strength-left img {
  width: 120%;
}

.strength-title,
.about-title {
  /* font-size: 24px; */
}

.strength-sub-title,
.about-sub-title {
  /* font-size: 24px; */
}

.content-strength .strength-desc {
  margin: 20px 0px;
  /* font-size: 12px; */
}

.content-strength .strength-right {
  display: inline-block;
  width: 38%;
  float: right;
  margin: 50px auto;
}

.strength-right .strength-item img {
  width: 40px;
  /* float: left; */
  margin-right: 30px;
}

.strength-right .strength-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.strength-right .strength-item-title {
  /* font-size: 12px; */
  margin-bottom: 14px;
}

.strength-right .strength-item-desc {
  line-height: 16px;
}

.case-content,
.news-content {
  padding: 50px 10% 0px 10%;
}

.case-title {
  /* font-size: 20px; */
  font-weight: bold;
}

.case-sub-title {
  color: #2573da;
}

.case-btn {
  text-align: right;
}

.case-item {
  position: relative;
  margin-top:20px;
  /* width:50%; */
  /* display: inline-block; */
}

.case-item a:hover{
  color: #009dff;
}


.case:hover {
  box-shadow: 2px 2px 10px #ccc;
}


.case-item .case {
  text-decoration: none;
  color: #525252;
  display: inline-block;
  width: 50%;
  padding: 20px 2% 0 2%;
   /* margin:0 2%; */
}

.case .case-img {
  display: inline-block;
  /* width:32%; */
  /* max-height:24px; */
  position: relative;
  width: 100%;
  height: 0;
  padding-top:40%;
}

.case-item .case img {
  /* width: 96%;
  height: 180px; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.case-item .case-right {
  /* margin:0 2%; */
}

.case-item .case-info {
  margin: 10px 0px;
}

.case-item .date-info {
   float: left; float: left;
  border-right: 1px solid #eee;
  padding: 0px 10px;
  color: #8d8d8d;
  text-align: center;
  vertical-align: top;
}

.date-info .date {
  /* font-size: 14px; */
}

.case-item .case-name {
  /* display: inline-block; */
  /* font-size: 14px; */
  margin-left: 10px;
  overflow: hidden;
  display: -webkit-box;
  float: left;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.about-content .title-about {
  padding: 50px 10% 0px 10%;
}

.about-content .about-item {
  position: relative;
  margin-top: 50px;
  padding: 20px 10% 0px 10%;
  background-color: #043d8a;
  color: #fff;
}

.about-content .about-left {
  width: 46%;
  float: left;
}

.about-left .about-desc {
  margin: 10px 0px;
}

.about-pro {
  width: 100%;
  text-align: center;
}

.about-pro .about-pro-item {
  display: inline-block;
  width: 25%;
}

.about-pro-item .pro-item-top {
  position: relative;
}

.pro-item-top .pro-item-num {
  /* font-size: 24px; */
}

.pro-item-top .pro-item-more {
  position: absolute;
  top: 4px;
}

.about-item .about-right {
  width: 54%;
  float: right;
  margin-bottom: -60px;
}

.about-right img {
  position: relative;
  top: -60px;
  width: 90%;
  /* height:120%; */
  margin-left: 50px;
  /* height:500px; */
}

.news-slider {
  width: 30%;
  box-shadow: 2px 2px 10px #ccc;
  display: inline-block;
  /* height:160px; */
  margin: 20px 0;
}

.news-slider a {
  text-decoration: none;
  color: #525252;
}

.news-slider a:hover{
  color: #009dff;
}

.news-center {
  margin: 0px 4%;
}

.news-left {
  margin-left: 1%;
}

.news-item {
  padding:20px 10px;
}

.news-img-item {
  position: relative;
  width: 100%;
  display: inline-block;
  padding-top: 66.6%;
  height: 0;
}

.news-item .news-img {
  width:100%;
  height:100%;
  position: absolute;
  bottom:0;
  left:0;
}

.news-item .news-title {
  /* font-size: 12px; */
  margin: 10px 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.news-item .news-time {
  margin-bottom: 10px;
  color: #999999;
}

.news-item .news-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-height: 40px;
  color: #525252;
}

.news-content /deep/ .carousel {
  min-height: 220px;
}

@media (max-width: 1530px) {
  .pro-left .sub-title {
    display: block;
  }
}

@media (max-width: 1360px) {
  /* .carousel-inner img {
        height: 320px;
    } */

  .pro-right {
    top: 40px;
  }

  .content-strength .strength-left {
    width: 100%;
    float: none;
  }

  .content-strength .strength-left img {
    width: 100%;
  }

  .content-strength .strength-right {
    width: 100%;
    float: none;
  }
  .about-content .about-item {
    padding: 0 10% 0 10%;
    min-height: 236px;
  }

  .about-content .about-left {
    padding-top: 20px;
    width: 58%;
  }

  .about-content .about-right {
    width: 42%;
    margin-bottom: 0px;
  }

  .about-right img {
    position: relative;
    top: 0px;
    width: 100%;
    /* height:120%; */
    margin-left: 50px;
    min-height: 236px;
    /* height:500px; */
  }
}

@media (max-width: 1024px) {
  /* .carousel-inner img {
        height: 240px;
    } */

  .pro-right {
    top: 0px;
  }

  .content-product {
    padding: 0px 4% 0px 10%;
  }

  .pro-left {
    width: 100%;
    display: block;
    margin: 0px;
  }

  .pro-left .sub-title {
    display: inline-block;
  }
  .pro-right {
    width: 100%;
    display: block;
    float:none;
  }

  /* .content-product /deep/ .carousel {
    text-align: center;
  } */

  .case-item .case {
    display: block;
    width: 100%;
    margin:0;
  }

  .about-content .about-left {
    padding-top: 20px;
    width: 100%;
    float: none;
  }

  .about-content .about-right {
    display: block;
    float: none;
    padding: 20px 0px;
    width: 100%;
  }

  .about-right img {
    position: static;
    width: 100%;
    /* height:120%; */
    margin-left: 0px;
    /* height:500px; */
  }

  .news-left {
    margin-left: 0;
  }

  .news-center {
    margin: 0;
  }

  .news-slider {
    width: 98%;
    display: block;
    float: none;
    margin-left: 1%;
  }

  .news-content /deep/ .carousel-indicators {
    bottom: -6px;
  }
}

@media (max-width: 768px) {
  .pro-right {
    margin-bottom:20px;
  }

  .content-product {
    padding: 10px;
    margin: 0;
  }

  .content-strength {
    padding: 10px;
  }

  .case-content {
    padding: 10px;
  }

  .news-content /deep/ .carousel-indicators {
    bottom: -12px;
  }

  .about-content .title-about {
    padding: 10px;
  }

  .about-content .about-item {
    padding: 10px;
  }

  .case-content,
  .news-content {
    padding: 10px;
  }
}

</style>