import request from '@/utils/request'

export function getNewsList(data) {
  return request({
    url: '/ybtWeb/queryNews',
    method: 'get',
    params: data
  })
} 

export function getSearchList(data) {
  return request({
    url: '/ybtWeb/getWebNewsPage',
    method: 'get',
    params: data
  })
} 

export function getNewsDetail(data) {
  return request({
    url: '/ybtWeb/newsDetail',
    method: 'get',
    params: data
  })
} 